<template>
  <div>
    <DeviceForm ref="DeviceForm" @payload="onPayload" />
    <TransferForm ref="TransferForm" @payload="onTransfer" />
    <CRow class="justify-content-center">
      <CCol xl="12" xxl="10">
        <Search @search="onSearch">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="cil-storage" />
                <strong>{{$t('cloud.title.bridge_device')}}</strong>
                <span v-if="count > 0"> ({{count}}) </span>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="list"
                :fields="FIELDS"
                :items-per-page="10"
              >
                <td slot="ops" slot-scope="{item, index}" class="text-center align-middle">
                  <el-dropdown trigger="click" @command="(command) => onOps(command, index, item)">
                    <span class="el-dropdown-link">
                      <i class="el-icon-more text-dark" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="subscription" icon="el-icon-finished" disabled>{{ $t('button.service_subscribe') }}</el-dropdown-item>
                      <el-dropdown-item command="edit" icon="el-icon-edit-outline">{{ $t('button.device_mod') }}</el-dropdown-item>
                      <el-dropdown-item command="transfer"><svgicon name="swap_horiz" width="16" height="16" /> {{ $t('button.device_transfer') }}</el-dropdown-item>
                      <el-dropdown-item command="delete" icon="el-icon-delete" disabled>{{ $t('button.device_remove') }}</el-dropdown-item>
                      <el-dropdown-item command="remote" disabled><svgicon name="swap_horiz" width="16" height="16" /> {{ $t('button.device_remote') }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>

                <td slot="status" slot-scope="{item}" class="align-middle">
                  <CBadge :color="getBadgeColor(item.status)">{{$t(`cloud.title.status_${item.status}`)}}</CBadge>
                </td>
              </CDataTable>
            </CCardBody>
          </CCard>
        </Search>
        <CRow class="justify-content-end" v-if="pageTotal > 1">
          <CPagination
            :activePage.sync="pageCurrent"
            :pages="pageTotal"
            @update:activePage="onUpdatePage"
          >
          </CPagination>
          <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue'
import DeviceForm from './Form'
import TransferForm from './TransferForm'

export default {
  name: 'DevicePortalIndex',
  components : {
    Search,
    DeviceForm,
    TransferForm
  },

  data(){
    return {
      FIELDS: [
        {key: 'name', label: this.$t('cloud.title.name')},
        {key: 'memo', label: this.$t('cloud.title.note')},
        {key: 'mac_addr', label: 'MAC'},
        {key: 'status', label: this.$t('cloud.title.status')},
        {key: 'ops', label: this.$t('cloud.title.operation'), _style: 'width:100px;text-align:center;', sorter: false}
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: []
    }
  },
  mounted(){
    this.getList();
  },
  methods :{
    getList(){
      let query_string = "";
      const params = {
        search : this.searchText,
        page : this.pageCurrent,
      };
      if (params){
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/bridge/${query_string}`)
      .then(result =>{
        this.list = this.$utility.copy(result.data.list);
        this.list = this.list.map(el => {
          el.status='unknown';
          if (el.memo === null) el.memo = '';
          return el;
        })
        this.pageTotal = result.data.page.total;
        this.pageCurrent = result.data.page.current;
        this.count = result.data.page.count;
      })
      .catch(error =>{
        console.error(error);
      })
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onSearch(text) {
      this.searchText = text.replace(/:/g,'');
      this.getList();
    },
    getBadgeColor (status) {
      const STATUS = {
        online: 'success',
        offline: 'danger',
        warning: 'warning',
        unknown: 'secondary'
      };
      return STATUS[status];
    },
    onPayload(data) {
      console.log(data);
      if (!data.id) {
        this.$notify.warning({
          title: this.$t('cloud.title.request_fail_title'),
          message: this.$t('cloud.title.request_fail_content'),
          offset: 30
        });
        return;
      }
      this.$confirm(this.$t('cloud.title.confirm_edit'), this.$t('cloud.title.confirm_title'), {
        confirmButtonText:this.$t('button.device_mod'),
        cancelButtonText:this.$t('button.cancel'),
        type:'warning'
      })
      .then(() => {
        axios.put(`/api/bridge/${data.id}/`, data)
          .then(() =>{
            this.$notify.success({
              title: this.$t('cloud.title.request_ok_title'),
              message: this.$t('cloud.title.request_ok_content'),
              offset: 30
            });
            this.getList();
          })
          .catch(error =>{
            console.error(error);
            this.$notify.error({
              title: this.$t('cloud.title.request_fail_title'),
              message: this.$t('cloud.title.request_fail_content'),
              offset: 30
            });
          })
      })
    },
    onTransfer(data) {
      if (!data.device_id) {
        this.$notify.warning({
          title: this.$t('cloud.title.request_fail_title'),
          message: 'No device ID',
          offset: 30
        });
        return;
      }
      if (!data.target_org_id) {
        this.$notify.warning({
          title: this.$t('cloud.title.request_fail_title'),
          message: 'No target organization id',
          offset: 30
        });
        return;
      }
      this.$confirm(this.$t('cloud.title.confirm_transfer'), this.$t('cloud.title.confirm_title'), {
        confirmButtonText:this.$t('button.device_transfer'),
        cancelButtonText:this.$t('button.cancel'),
        type:'warning'
      })
      .then(() => {
        axios.patch(`/api/bridge/${data.device_id}/`, data)
          .then(() =>{
            this.$notify.success({
              title: this.$t('cloud.title.request_ok_title'),
              message: this.$t('cloud.title.request_ok_content'),
              offset: 30
            });
            this.getList();
          })
          .catch((error) => {
            let error_msg = error.response.data.error;
            if (error.response.data.error_detail) {
              error_msg += `: ${error.response.data.error_detail}`;
            }
            this.$notify.error({
              title: this.$t('cloud.title.request_fail_title'),
              message: error_msg,
              offset: 30
            });
          })
      })
    },
    onOps(cmd, index, item) {
      if (cmd === 'edit') {
        this.$refs.DeviceForm.open(item);
      } else if (cmd === 'transfer') {
        this.$refs.TransferForm.open(item.id);
      } else if (cmd === 'delete') {
        alert('delete');
      }
    }
  }

}
</script>
