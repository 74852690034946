<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <CCard>
      <CCardBody>
        <CInput
          label="Name"
          placeholder="Device name"
          horizontal
          :value.sync="device_updated.name"
        />
        <CInput
          label="Memo"
          placeholder="Client / Location, etc."
          horizontal
          :value.sync="device_updated.memo"
        />
      </CCardBody>
    </CCard>
    <template #header>
      <h6 class="modal-title">AI Bridge</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton @click="onSubmit" color="primary">{{$t('button.apply')}}</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'AIBridgeForm',

  data() {
    return {
      show: false,

      device_updated: {
        id: null,
        name: null,
        memo: null
      },
      notification: '',
      dismissCountDown: 0
    }
  },
  methods: {
    open(device) {
      this.device_updated = this.$utility.copy(device);
      this.show = true;
    },
    onCancel() {
      this.show = false;
    },
    validateAndWarning() {
      if (!this.device_updated.name) {
        this.notification = this.$t('cloud.title.validate.name');
        this.dismissCountDown = 3;
        return true;
      }
      return null;
    },
    onSubmit() {
      if (this.validateAndWarning()) {
        return;
      }

      this.$emit('payload', this.device_updated);
      this.onCancel();
    }
  }
}
</script>